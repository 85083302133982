import { useLocation } from '@reach/router';
import queryString from 'query-string';
import React, { useRef, useState } from 'react';
import { Card, Col, Container, Image, Row } from 'react-bootstrap';

import Authenticate from '../components/authentication/authenticate';
import Framework from '../components/framework';
import Language from '../components/framework/language';
import NavTop from '../components/framework/nav-top';
import PartnerBranding from '../components/framework/partner-branding';
import Seo from '../components/framework/seo';
import Suspense from '../components/framework/suspense';
import HelpTag from '../components/help/tag';
import useTranslation from '../hooks/use_translation';
import svg from '../images/activate/lines.svg';
import logo from '../images/framework/logo-light.svg';

const Activate = () => {
	const location = useLocation();
	const hash = queryString.parse(location?.hash);
	const search = queryString.parse(location?.search);
	const signInSectionRef = useRef(null);
	const [alertCopy, setAlertCopy] = useState('');
	const { t } = useTranslation('activate');

	const copyToClipboard = async () => {
		const textToCopy = search?.code;
		if (!textToCopy) return;

		try {
			const permissions = await navigator.permissions.query({
				name: 'clipboard-write',
			});
			if (permissions.state === 'granted' || permissions.state === 'prompt') {
				await navigator.clipboard.writeText(textToCopy);
				setAlertCopy(t('t11'));
			}
		} catch (error) {
			//
		}

		if (signInSectionRef.current) {
			signInSectionRef.current.scrollIntoView({ behavior: 'smooth' });
		}
	};

	return (
		<Framework>
			<Seo title={t('title')} />
			<NavTop></NavTop>

			<Container className="mt-5 pt-5 signin signin-activate">
				<Row className="justify-content-center">
					<Col lg="4">
						<div
							className="block-container"
							onClick={copyToClipboard}
							role="button"
							tabIndex="0"
						>
							<div className="btn-back btn-back-1 d-flex align-items-center justify-content-center">
								<Image
									alt="logo"
									className="img-fluid opacity-50"
									src={logo}
									style={{ width: '80%' }}
								></Image>
							</div>
							<Card className="btn-front shadow shadow-lg">
								<Card.Body className="text-center py-6 text-white position-relative">
									<div className=" position-absolute z-2 top-0 bottom-0 end-0 h-100 d-flex align-items-center justify-content-center start-0">
										<div>
											<h1 className="text-center large opacity-50">
												{t('t10')}{' '}
											</h1>
											<span className="lead">{search?.code}</span>
											{alertCopy && (
												<>
													<p
														className={`sm mb-0 ${
															alertCopy == 'success'
																? 'text-danger'
																: 'text-success'
														}`}
													>
														{alertCopy}
													</p>
												</>
											)}
										</div>
									</div>
									<Image
										alt={t('t10')}
										className="img-fluid position-absolute top-0 w-100 start-0 z-1"
										src={svg}
									></Image>
								</Card.Body>
							</Card>
						</div>
						<div className="my-5" ref={signInSectionRef}>
							<div className="mx-auto mb-lg-4 mb-3 text-center">
								<PartnerBranding logo={logo} />
							</div>

							<Suspense tags={{ component: 'Activate' }}>
								<h1 className="h3 text-center pb-2">
									{t('t1')}
									<HelpTag className="p-2" id="signin_signup" />
								</h1>

								<Authenticate
									method={hash?.method}
									redirect_url={location.pathname + location.search}
									sid={hash?.sid}
								/>
							</Suspense>
						</div>
						<div className="d-flex justify-content-center">
							<Language />
						</div>
					</Col>
				</Row>
			</Container>
		</Framework>
	);
};

export default Activate;
